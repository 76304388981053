@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    color: rgb(15, 23, 42);
  }
}

.MuiPickersDay-root.Mui-selected {
  border-radius: 9999px;
}

.Mui-disabled {
  border-color: rgb(203, 213, 225);
}

[class*='MuiStepConnector-active'] {
  border-color: rgb(96, 165, 250);
}

[class*='MuiStepConnector-complete'] {
  border-color: rgb(96, 165, 250);
}

[class*='MuiStepConnector-lineHorizontal'] {
  border-top-width: 2px;
}

.MuiToggleButton-root.Mui-selected {
  background-color: rgb(96, 165, 250) !important;
  color: rgb(255, 255, 255) !important;
}

/* Hide arrow buttons on number inputs */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiBreadcrumbs-separator {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.MuiBreadcrumbs-li {
  display: flex;
  align-items: center;
}
